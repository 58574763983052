import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../Utils/API";
import { updateAuth } from "../../Redux/AuthSlice";

const AdminLogin = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const AutoLoginApi = async () => {
    try {
      const userId = searchParams.get("user_id");
      const WalletAddress = searchParams.get("enpass");

      let response = await axios.get("https://api64.ipify.org/?format=json");
      let ipaddress = response.data.ip;

      let res = await API.post(`/userlogin`, {
        password: WalletAddress,
        ipaddress: ipaddress,
      });
      // console.log("UserloginNew Auto", res);
      if (res.data.data.result === "Successfull") {
        toast.success(`Login Successful`);
        // console.log("loginmask", res.data.token);
        dispatch(
          updateAuth({
            isAuth: true,
            userId: res.data.data.uid_output,
            jwtToken: res.data.token,
            ipAddress: ipaddress,
          })
        );
        navigate("/dashboard");
      } else {
        toast.error(res.data.data.result);
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  useEffect(() => {
    AutoLoginApi();
  }, []);

  return <div></div>;
};

export default AdminLogin;
