import React, { useState, useEffect } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";
import Sidebar from "../DashboardMain/Sidebar";

const Direct_User_List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [search, setSearch] = useState("");
  const [position, setPosition] = useState(0);
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [todate, setTodate] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [totalamount, settotalamount] = useState(0);
  const [totalid, settotalid] = useState(0);
  const user = useSelector((state) => state.Auth.userId);

  const Direct_API = async () => {
    try {
      let responce = await API?.post(
        `/myDirect`,
        {
          uid: userId,
          searchid: search,
          fdate: fromdate,
          tdate: todate,
          status: 2,
          position: position,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("activationHistory", responce);
      let countid = responce.data.totalid;
      settotalid(countid);
      let responce1 = responce.data.totalamount;
      settotalamount(responce1);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          Registration_Date: item.registrationDate,
          User_ID: item.uid,
          Position: item.position,
          Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
            item.wallet.length - 4
          )}`,
          Status: item.status,

          Latest_Package: `${
            item?.currentPackage != null ? `$ ${item.currentPackage}` : "N/A"
          }`,
          Gross_Package: `${
            item?.totalPackage != null ? `$ ${item.totalPackage}` : "N/A"
          }`,

          Activation_Date: item.activationDate || "N/A",
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Direct_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Registration_Date", accessor: "Registration_Date" },
      { Header: "Position", accessor: "Position" },
      { Header: "Status", accessor: "Status" },
      { Header: "Latest Package", accessor: "Latest_Package" },
      { Header: "Gross Package", accessor: "Gross_Package" },
      { Header: "Activation Date", accessor: "Activation_Date" },
    ],
  });

  const handleUserIdChange = (e) => {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 7);
    const sanitizeValue = DOMPurify.sanitize(inputText);
    setSearch(sanitizeValue);
  };

  const handlePositionChange = (value) => {
    setPosition(value);
  };

  // togglar
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}>
              <Sidebar />
            </div>
          </div>

          {/* Main Content */}
          <div className="col">
            <div className="flex-grow-1 marginl">
              {/* Header */}
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                {/* <div className="fw-bold fs-4 headingdah">METARAGS</div> */}
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:-
                      <span>{user}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}>
                  ☰
                </button>
              </header>

              {/* Content Area */}
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}>
                <div className="row g-3 mx-0 h-100">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fs-18 text-white fw-bold">
                        Direct User List ( Total Id : {totalid} ) ( Total Amount
                        : {totalamount} )
                      </div>
                      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Direct List</div>
                        <div className="ps-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                              <li className="breadcrumb-item">
                                <a href="javascript:;">
                                  <i className="bx bx-home-alt text-white" />
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page">
                                Direct User List
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mx-0 g-3 mb-3">
                      <div className="col-md-2">
                        <label className="text-white fs-14">User Id</label>
                        <input
                          type="text"
                          name="from_date"
                          id="from_date"
                          className="form-control text-white shadow-none text-white bgmain"
                          placeholder="User ID"
                          value={search}
                          onChange={handleUserIdChange}
                        />
                      </div>
                      <div className="col-md-2">
                        <label className="text-white fs-14">Position</label>
                        <select
                          className="form-control shadow-none bgmain"
                          id="status"
                          style={{
                            color: "rgb(255, 255, 255)",
                            background: "#E5B80B",
                          }}
                          value={position}
                          onChange={(e) =>
                            handlePositionChange(e.target.value)
                          }>
                          <option
                            value=""
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            Select Position
                          </option>
                          <option
                            value={0}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            All
                          </option>
                          <option
                            value={1}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            Left
                          </option>
                          <option
                            value={2}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            Right
                          </option>
                        </select>
                      </div>
                      <div className="col-md-2 text-white">
                        <label> From Date</label>
                        <input
                          type="date"
                          name="from_date"
                          onChange={(e) =>
                            setFromdate(DOMPurify.sanitize(e.target.value))
                          }
                          id="from_date"
                          className="form-control"
                          placeholder="dd-mm-yyyy"
                        />
                      </div>
                      <div className="col-md-2 text-white">
                        <label> To Date</label>
                        <input
                          type="date"
                          placeholder="dd-mm-yyyy"
                          onChange={(e) =>
                            setTodate(DOMPurify.sanitize(e.target.value))
                          }
                          name="to_date"
                          id="to_date"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-auto mt-1">
                        <input
                          type="submit"
                          name="to_date"
                          className="btn btn-primary mt-0 mt-md-5 bgmain border-0 px-3 shadow-none w-auto"
                          defaultValue="Search"
                          onClick={() => Direct_API()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 jashja ">
                    <div className="table-responsive">
                      <Table
                        data={[...referralApi]}
                        columns={matching_income.cols}
                      />
                      <Table_Buttons
                        indexOfFirstPage={indexOfFirstPage}
                        indexOfLastPost={indexOfLastPost}
                        setcurrentPage={setcurrentPage}
                        currentPage={currentPage}
                        totalData={totalCount}
                        listPerpage={listPerpage}
                        handleRowsPerPageChange={setlistPerpage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Direct_User_List;
