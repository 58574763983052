import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { updateAuth } from "../../Redux/AuthSlice";
import Sidebar from "../DashboardMain/Sidebar";

const IncomeLimit = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const FundHistory_API = async () => {
    try {
      let responce = await API.post(
        "IncomeLimitReport",
        {
          uid: user,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      // console.log("res-->",responce)
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setreferralApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          Amount: `$ ${item.packageamount}`,
          dd: item.dd,
          token: item.Incomelimit,
          usdt: item.Reaminamount,
          type: item.Business,
          rincome: item.rincome,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      // console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    FundHistory_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Package Amount", accessor: "Amount" },
      { Header: "Income Limit", accessor: "token" },
      { Header: "Total Income", accessor: "usdt" },
      { Header: "Remaining Income", accessor: "rincome" },
      //{ Header: "Matching Business", accessor: "type" },
      { Header: "Date", accessor: "dd" },
    ],
  });
  // togglar
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}>
              <Sidebar />
            </div>
          </div>

          {/* Main Content */}
          <div className="col">
            <div className="flex-grow-1 marginl">
              {/* Header */}
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:- <span>{user}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}>
                  ☰
                </button>
              </header>

              {/* Content Area */}
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}>
                <div className="row mx-0 h-100">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fs-18 text-white fw-bold">
                        {" "}
                        Income Limit
                      </div>
                      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">
                          {" "}
                          Activate / Upgrade
                        </div>
                        <div className="ps-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                              <li className="breadcrumb-item">
                                <a href="">
                                  <i className="bx bx-home-alt text-white" />
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page">
                                Income Limit
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 jashja">
                    <Table
                      data={[...referralApi]}
                      columns={matching_income.cols}
                    />
                    <Table_Buttons
                      indexOfFirstPage={indexOfFirstPage}
                      indexOfLastPost={indexOfLastPost}
                      setcurrentPage={setcurrentPage}
                      currentPage={currentPage}
                      totalData={totalCount}
                      listPerpage={listPerpage}
                      handleRowsPerPageChange={setlistPerpage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeLimit;
