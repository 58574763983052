import React, { useState, useEffect } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";
import Sidebar from "../DashboardMain/Sidebar";

const Downline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [search, setSearch] = useState("");
  const [fdate, setFdate] = useState("");
  const [tdate, setTdate] = useState("");
  const [totalamount, settotalamount] = useState(0);
  const [position, setPosition] = useState(0);
  const [totalid, settotalid] = useState(0);
  const user = useSelector((state) => state.Auth.userId);

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  //console.log("12", listPerpage, currentPage);
  const Downline_API = async () => {
    try {
      let responce = await API?.post(
        `/myDownline`,
        {
          uid: userId,
          searchid: search,
          fdate: fdate,
          tdate: tdate,
          status: 2,
          position: position,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      //console.log("activationHistory", responce);
      let countid = responce.data.totalid;
      settotalid(countid);
      let totalamount = responce.data.totalamount;
      settotalamount(totalamount);
      responce = responce?.data?.data[0];

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Position: item?.position,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          Status: item?.status,

          packagename: `$ ${item?.totalPackage}`,
          Activation_Date:
            item?.activationDate == null ? "" : item?.activationDate,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Downline_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Registration_Date", accessor: "Registration_Date" },
      { Header: "Position", accessor: "Position" },
      { Header: "Package", accessor: "packagename" },
      { Header: "Status", accessor: "Status" },

      { Header: "Activation Date", accessor: "Activation_Date" },
    ],
  });

  const handleUserIdChange = (e) => {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 7);
    const sanitizeValue = DOMPurify.sanitize(inputText);
    setSearch(sanitizeValue);
  };

  const handlePositionChange = (value) => {
    setPosition(value);
  };
  // togglar
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}>
              <Sidebar />
            </div>
          </div>

          {/* Main Content */}
          <div className="col">
            <div className="flex-grow-1 marginl">
              {/* Header */}
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                {/* <div className="fw-bold fs-4 headingdah">METARAGS</div> */}
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:-
                      <span>{user}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}>
                  ☰
                </button>
              </header>

              {/* Content Area */}
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}>
                <div className="row mx-0 g-3 h-100">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fs-18 text-white fw-bold">
                        Downline ( Total ID : {totalid} ) ( Total Amount :{" "}
                        {totalamount} )
                      </div>
                      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Downline</div>
                        <div className="ps-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                              <li className="breadcrumb-item">
                                <a href="javascript:;">
                                  <i className="bx bx-home-alt text-white" />
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page">
                                Downline
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mx-0 g-3 mb-3">
                      <div className="col-md-2">
                        <label className="text-white fs-14">User Id</label>
                        <input
                          type="text"
                          name="search"
                          id="search"
                          className="form-control text-white shadow-none text-white bgmain"
                          placeholder="Enter User Id"
                          value={search}
                          onChange={handleUserIdChange}
                        />
                      </div>
                      <br />
                      <br />
                      <div className="col-md-2">
                        <label className="text-white fs-14">Position</label>
                        <select
                          className="form-control shadow-none bgmain"
                          id="position"
                          style={{
                            color: "rgb(255, 255, 255)",
                            background: "#E5B80B",
                          }}
                          value={position}
                          onChange={(e) =>
                            handlePositionChange(e.target.value)
                          }>
                          <option
                            value={0}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            All
                          </option>
                          <option
                            value={1}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            Left
                          </option>
                          <option
                            value={2}
                            style={{
                              color: "rgb(255, 255, 255)",
                              background: "#E5B80B",
                            }}>
                            Right
                          </option>
                        </select>
                      </div>
                      <br />
                      <br />

                      <div className="col-md-2">
                        <label className="text-white fs-14">From Date</label>
                        <input
                          type="date"
                          name="fdate"
                          id="fdate"
                          className="form-control text-white shadow-none text-white bgmain"
                          value={fdate}
                          onChange={(e) => setFdate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label className="text-white fs-14">To Date</label>
                        <input
                          type="date"
                          name="tdate"
                          id="tdate"
                          className="form-control text-white shadow-none text-white bgmain"
                          value={tdate}
                          onChange={(e) => setTdate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-auto mt-1">
                        <input
                          type="submit"
                          name="to_date"
                          className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                          defaultValue="Search"
                          onClick={() => Downline_API()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 jashja">
                    <div className="table-responsive">
                      <Table
                        data={[...currentPost]}
                        columns={matching_income.cols}
                      />
                      <Table_Buttons
                        setcurrentPage={setcurrentPage}
                        currentPage={currentPage}
                        totalData={referralApi.length}
                        listPerpage={listPerpage}
                        handleRowsPerPageChange={setlistPerpage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downline;
