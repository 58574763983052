import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Footer/Footer";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import DashboardMain from "./Components/DashboardMain/DashboardMain";
import Adjustment from "./Components/Adjustment/Adjustment";
import Sell_Rate_Chart from "./Components/Sell_Rate_Chart/Sell_Rate_Chart";
import Add_Fund from "./Components/Add_Fund/Add_Fund";
import Fund_Report from "./Components/Fund_Report/Fund_Report";
import Activate_Upgrade from "./Components/Activate_Upgrade/Activate_Upgrade";
import Activate_Upgrade_History from "./Components/Activate_Upgrade_History/Activate_Upgrade_History";
import Buy_Token from "./Components/Buy_Token/Buy_Token";
import Buy_Token_Report from "./Components/Buy_Token_Report/Buy_Token_Report";
import Sell_Token from "./Components/Sell_Token/Sell_Token";
import Sell_Token_Report from "./Components/Sell_Token_Report/Sell_Token_Report";
import Auto_Sell_Token_Report from "./Components/Auto_Sell_Token_Report/Auto_Sell_Token_Report";
import Tree_View from "./Components/Tree_View/Tree_View";
import Direct_User_List from "./Components/Direct_User_List/Direct_User_List";
import Level_Details from "./Components/Level_Details/Level_Details";
import Downline from "./Components/Downline/Downline";
import Matrix_Downline from "./Components/Matrix_Downline/Matrix_Downline";
import Direct_Earning_Income from "./Components/Direct_Earning_Income/Direct_Earning_Income";
import Binary_Earning_Report from "./Components/Binary_Earning_Report/Binary_Earning_Report";
import Matrix_Earning from "./Components/Matrix_Earning/Matrix_Earning";
import Matrix_CTO_Earning from "./Components/Matrix_CTO_Earning/Matrix_CTO_Earning";
import Withdrawal_Direct_Earning from "./Components/Withdrawal_Direct_Earning/Withdrawal_Direct_Earning";
import DirectRewardBusiness from "./Components/DirectRewardBusiness/DirectRewardBusiness";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import Withdrawal_History from "./Components/Withdrawal_History/Withdrawal_History";
import Privateroutes from "./Utils/Privateroutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import ActivateByFund from "./Components/Add_Fund/ActivateByFund";
import ActivationByFundReport from "./Components/Fund_Report/ActivationByFundHistory";
import AdminFundExpiredHistory from "./Components/Fund_Report/AdminFundExpiredHistory";
import ROI_Level_Income from "./Components/ROI_Level_Income/ROI_Level_Income";
import Daily_all_income from "./Components/ROI_Level_Income/Daily_all_income";
import Gross_Binary_Earning_Report from "./Components/Gross_Binary_Earning_Report/Gross_Binary_Earning_Report";
import Helpdesk from "./Components/Helpdesk/Helpdesk";
import GameWalletTransfer from "./Components/GameWallet T/GameWalletTransfer";
import MatchingBusiness from "./Components/Gross_Binary_Earning_Report/MatchingBusiness";
import IncomeLimit from "./Components/Fund_Report/IncomeLimit";
import GameAmountTransfer from "./Components/GameWallet T/GameAmountTransfer";
import GameAmountTransferHistory from "./Components/GameWallet T/GameAmountTransferHistory";
import GameAmountReceivedHistory from "./Components/GameWallet T/GameAmountReceivedHistory";
import RankIncome from "./Components/RankIncome/RankIncome";
import RankDownline from "./Components/Downline/RankDownline";
import AdminLogin from "./Components/Login/AdminLogin";

// import TransferFund from "./Components/TransferFund/TransferFund";
// import Transferhistory from "./Components/TransferFund/Transferhistory";
// import ReceiveHistory from "./Components/TransferFund/ReceiveHistory";

function App() {
  //   useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //     debugger;
  //   });

  //   document.onkeydown = function (e) {
  //     if (
  //       (e.ctrlKey && (e.keyCode === 73 || e.keyCode === 85)) ||
  //       e.keyCode === 123
  //     ) {
  //       return false;
  //     }
  //   };
  // }, []);

  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Hero />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/AdminLogin" element={<AdminLogin />}></Route>
          <Route path="/Register" element={<Register />}></Route>
          <Route element={<Privateroutes />}>
            <Route path="/DashBoard" element={<DashboardMain />}></Route>

            <Route path="/Adjustment" element={<Adjustment />}></Route>
            <Route
              path="/Sell_Rate_Chart"
              element={<Sell_Rate_Chart />}></Route>
            <Route path="/Activate" element={<Add_Fund />}></Route>
            <Route path="/ActivateByFund" element={<ActivateByFund />}></Route>
            <Route path="/History" element={<Fund_Report />}></Route>
            <Route
              path="/Activate_Upgrade"
              element={<Activate_Upgrade />}></Route>
            <Route
              path="/Activate_Upgrade"
              element={<Activate_Upgrade />}></Route>
            <Route
              path="/ActivationByFundReport"
              element={<ActivationByFundReport />}></Route>
            <Route path="/Buy_Token" element={<Buy_Token />}></Route>
            <Route
              path="/Buy_Token_Report"
              element={<Buy_Token_Report />}></Route>
            <Route path="/Sell_Token" element={<Sell_Token />}></Route>
            <Route
              path="/Sell_Token_Report"
              element={<Sell_Token_Report />}></Route>
            <Route
              path="/Auto_Sell_Token_Report"
              element={<Auto_Sell_Token_Report />}></Route>
            <Route path="/Tree_View" element={<Tree_View />}></Route>
            <Route
              path="/Direct_User_List"
              element={<Direct_User_List />}></Route>
            <Route path="/Level_Details" element={<Level_Details />}></Route>
            <Route path="/Downline" element={<Downline />}></Route>
            <Route
              path="/Direct_Leg_Business"
              element={<Matrix_Downline />}></Route>
            <Route
              path="/Direct_Income"
              element={<Direct_Earning_Income />}></Route>
            <Route
              path="/Matching_income"
              element={<Binary_Earning_Report />}></Route>
            <Route
              path="/Differential_income"
              element={<Matrix_Earning />}></Route>
            <Route
              path="/Booster_income"
              element={<Matrix_CTO_Earning />}></Route>
            <Route
              path="/Profit_Share_Income"
              element={<Withdrawal_Direct_Earning />}></Route>
            <Route
              path="/Salary_income"
              element={<DirectRewardBusiness />}></Route>
            <Route path="/Withdrawal" element={<Withdrawal />}></Route>
            <Route
              path="/Withdrawal_History"
              element={<Withdrawal_History />}></Route>
            <Route
              path="/AdminFundExpiredHistory"
              element={<AdminFundExpiredHistory />}></Route>
            <Route
              path="/Roi_Income"
              element={<Withdrawal_Direct_Earning />}></Route>
            <Route
              path="/ROI_Level_Income"
              element={<ROI_Level_Income />}></Route>
            <Route path="/RankIncome" element={<RankIncome />}></Route>
            <Route
              path="/Daily_all_income"
              element={<Daily_all_income />}></Route>
            <Route
              path="/Gross_Matching_income"
              element={<Gross_Binary_Earning_Report />}></Route>
            <Route path="/gamewallet" element={<GameWalletTransfer />}></Route>
            <Route path="/Helpdesk" element={<Helpdesk />}></Route>
            <Route path="/IncomeLimit" element={<IncomeLimit />}></Route>
            <Route
              path="/GameAmountTransfer"
              element={<GameAmountTransfer />}></Route>
            <Route
              path="/GameAmountTransferHistory"
              element={<GameAmountTransferHistory />}></Route>
            <Route
              path="/GameAmountReceivedHistory"
              element={<GameAmountReceivedHistory />}></Route>
            <Route
              path="/MatchingBusiness"
              element={<MatchingBusiness />}></Route>
            <Route
              path="/RankDownline/:rankid"
              element={<RankDownline />}></Route>

            {/* <Route path="/transferfund" element={<TransferFund />}></Route>
          <Route path="/transferhistory" element={<Transferhistory />}></Route>
          <Route path="/receive_History" element={<ReceiveHistory />}></Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
