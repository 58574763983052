import React, { useState } from "react";
import "./DashHeader.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HiOutlineHome } from "react-icons/hi";
import { FaHardDrive } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";
import { IoIosLock } from "react-icons/io";
import { MdSell } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";

const DashHeader = () => {
  const dispatch = useDispatch();

  // Logout handler
  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
  }

  // Sidebar toggler
  // const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  // const toggleSidebar = () => {
  //   setSidebarCollapsed(!isSidebarCollapsed);
  // };

  const { userId, status } = useSelector((state) => state.Auth);

  return (
    <div className="dash-header">
      

    </div>
    
  );
};

export default DashHeader;
